import React, { useEffect, useContext } from 'react';
import { Frame, Page, Layout } from '@shopify/polaris';
import { Redirect } from '@shopify/app-bridge/actions';
import { Context as ShopifyContext } from '@shopify/app-bridge-react';
import { useQuery } from '@apollo/client';
import { useQueryClient } from 'react-query';
import { css } from '@emotion/css';

import axios from '../utils/api/bundle-shopify';
import {
  GET_SUBSCRIPTION_CONTRACTS,
  GET_SHOP_PAYMENTS_INFO,
} from '../lib/graphql';
import { useShopContext } from '../components/Shop';
import AwtomicLoading from '../components/AwtomicLoading';

const PAGE_PLANS = '/plans';
const PAGE_SUBSCRIPTIONS = '/subscriptions';
const PAGE_WELCOME_GUIDE = '/launch';

/*
Cheks the state of the merchant and decides to take the user to one page or another.
*/
export default function Home() {
  const queryClient = useQueryClient();

  const prefetchSubscriptions = async () => {
    await queryClient.prefetchQuery(
      'subscription-contracts',
      async () => await axios.get('/subscription-contracts'),
      {
        staleTime: 10000, // only eligible to refetch after 10 seconds
      },
    );
  };

  prefetchSubscriptions();
  const redirect = Redirect.create(useContext(ShopifyContext));
  const { shop } = useShopContext();

  const { loading: loadingContracts, data: contractsData } = useQuery(
    GET_SUBSCRIPTION_CONTRACTS.gql,
    {
      variables: { first: 1 },
    },
  );

  const { loading: loadingEligibility, data: shopData } = useQuery(
    GET_SHOP_PAYMENTS_INFO.gql,
  );

  useEffect(() => {
    if (loadingContracts || loadingEligibility) return;
    const hasSubscriptionContracts =
      contractsData?.subscriptionContracts?.edges?.length > 0;
    const notEligibleForSubscriptions =
      !shopData.shop.features.eligibleForSubscriptions;

    /**
     * If shop is not elegible for subscriptions or shop doesn't have a pricing plan
     *  Then go to setup guide
     * Else If shop has subscription contracts
     *  Then go to subscriptions page
     * Else
     *  Then go to plans page
     */
    const redirectPage =
      notEligibleForSubscriptions || !shop?.AppSubscriptionName
        ? PAGE_WELCOME_GUIDE
        : hasSubscriptionContracts
          ? PAGE_SUBSCRIPTIONS
          : PAGE_PLANS;

    redirect.dispatch(Redirect.Action.APP, redirectPage);
  });

  return (
    <Frame>
      <Page>
        <Layout>
          <Layout.Section>
            <div className={styles.loader}>
              <AwtomicLoading />
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  );
}

const styles = {
  loader: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20% 0;
  `,
};
